import Vue from 'vue';

import { i18n, i18nSetPublicLocale } from '@/i18n/index.js';

import FCANotificationWidget from '@/components/FCANotificationWidget/FCANotificationWidget.vue';

const loader = document.getElementById('fca-notification-loader')!;

async function init(lang: string) {
  if (!document) {
    return;
  }

  await i18nSetPublicLocale(lang);

  new Vue({
    i18n,
    render: (h) => h(FCANotificationWidget),
    mounted() {
      const cryptoWidgetAppWrapper = document.getElementById(
        'fca-notification-app-wrapper',
      );
      if (cryptoWidgetAppWrapper) {
        cryptoWidgetAppWrapper.style.display = '';
      }
      loader?.remove();
    },
  }).$mount('#fca-notification-app');
}

init(loader.dataset.lang!);
