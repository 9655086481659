<template>
  <div class="fca-notification">
    <div class="container fca-notification__message">
      <i18n path="fca-notification.banner.paragraph">
        <template v-slot:link>
          <a class="btn-link" @click.prevent="onOpenModal">{{
            $t("fca-notification.banner.paragraph.link")
          }}</a>
        </template>
      </i18n>
    </div>
    <FCANotificationWidgetModal @close="onCloseModal" :show="showModal" />
  </div>
</template>

<script type="ts" setup>
import FCANotificationWidgetModal from '@/components/FCANotificationWidget/FCANotificationWidgetModal.vue';
import { ref } from 'vue';

const showModal = ref(false);

function onOpenModal() {
  showModal.value = true;
}

function onCloseModal() {
  showModal.value = false;
}
</script>
